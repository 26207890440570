<template>
 <v-card flat color="body2">
   <v-container>
    <v-row>
      <v-col class="pt-0">
         <v-img eager class="separator-size d-inline-flex" src="assets/separator-blue-top.svg" />
      </v-col>
    </v-row>
    <v-row class="pb-10">
      <v-col cols="12">
        <h2>LOS CEREBROS</h2>
      </v-col>
    </v-row>         
     <v-row>
       <row-fotos :items="equipo1"/>
     </v-row>
     <v-row>
       <row-fotos :items="equipo2"/>
     </v-row>
     <v-row class="pb-10">
       <v-col cols="12" lg="4" offset-lg="4">
         <news-button :no-block="$vuetify.breakpoint.mobile" title="INSCRIBITE A NUESTRAS NEWS" />
       </v-col>
     </v-row>
   </v-container>
 </v-card>
</template>

<script>
export default {
  name: 'Cerebros',
  components: {
    RowFotos: () => import('@/views/components/RowFotos'),
    NewsButton: () => import('@/views/components/NewsButton'),
  },
  data() {
    return {
      equipo1: [
        {
          nombre: 'ANA',
          foto: 'assets/equipo-ana-elisa-de-iparraguirre.png',
          fullNombre: 'ANA ELISA DE IPARRAGUIRRE', 
          rol: 'LEGAL ANALYST', 
          descripcion: 'Analiza la normativa argentina respecto a la tecnología blockchain para definir los caminos que hacen posible TOKENFIT.',
          socials: [
            {
              type: 'linkedin',
              url: 'https://www.linkedin.com/in/ana-elisa-de-iparraguirre-95701641/'
            }
          ]
        },
        {
          nombre: 'LAURA',
          foto: 'assets/equipo-maria-laura-dalmasso.png',
          fullNombre: 'MARIA LAURA DALMASSO', 
          rol: 'PROJECT MANAGER', 
          descripcion: 'La mirada puesta en la visión, organización y desarrollo de TOKENFIT.',
          socials: [
            {
              type: 'linkedin',
              url: 'https://www.linkedin.com/in/licmarialauradalmasso/'
            }
          ]
        },
        {
          nombre: 'LUKAS',
          foto: 'assets/equipo-lukas-leiva.png',
          fullNombre: 'LUKAS LEIVA', 
          rol: 'PRODUCT OWNER', 
          descripcion: 'Lleva adelante la visión estratégica para desarrollar una "Economía de Tokenización" argentina en Real Estate.',
          socials: [
            {
              type: 'linkedin',
              url: 'https://www.linkedin.com/in/lucasmleiva/'
            }
          ]
        },
        {
          nombre: 'LEO',
          foto: 'assets/equipo-leonardo-salvucci.png',
          fullNombre: 'LEONARDO SALVUCCI', 
          rol: 'BLOCKCHAIN ARCHITECT', 
          descripcion: 'Respalda, desarrolla y moviliza la ejecución coordinada del producto para la evolución del proyecto.',
          socials: [
            {
              type: 'linkedin',
              url: 'https://www.linkedin.com/in/leonardosalvucci/'
            }
          ]
        },
      ],
      equipo2: [
        {
          nombre: 'STELLA',
          foto: 'assets/equipo-stella-echevarria.png',
          fullNombre: 'STELLA ECHEVARRIA', 
          rol: 'COMMUNICATIONS', 
          descripcion: 'Crea, organiza y plasma la vida de TOKENFIT en nuestras RRSS.',
          socials: [
            {
              type: 'linkedin',
              url: 'https://www.linkedin.com/in/echevarriastellamaris/'
            }
          ]
        },
        {
          nombre: 'BENJAMÍN',
          foto: 'assets/equipo-benjamin-goyallon.png',
          fullNombre: 'BENJAMÍN GOYALLÓN', 
          rol: 'GRAPHIC DESIGNER', 
          descripcion: 'El artista que diseña la imagen del proyecto TOKENFIT.',
          socials: [
            {
              type: 'linkedin',
              url: 'https://www.linkedin.com/in/benjamin-goyallon-13b81bbb/'
            }
          ]
        },
      ]      
    }
  }
}
</script>

<style>

</style>